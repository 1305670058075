import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CategoryView from '@/views/CategoryView.vue'
import PageNotFoundView from '@/views/PageNotFoundView.vue'
import TagsView from '@/views/TagsView.vue'
import TypeView from '@/views/TypeView.vue'
import ProductView from '@/views/ProductView.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView,
    },
    {
        path: '/:type',
        name: 'Type',
        component: TypeView,
        props: true
    },
    {
        path: '/:type/:id',
        name: 'Category',
        component: CategoryView,
        props: true
    },
    {
        path: '/tags/:type/:id',
        name: 'Tags',
        component: TagsView,
        props: true
    },    
    {
        path: '/product/:type/:category/:id',
        name: 'ProductCategory',
        component: ProductView,
        props: true
    },
    {
        path: '/product/:type/:id',
        name: 'Product',
        component: ProductView,
        props: true
    },
    {
        path: '/:catchAll(.*)',
        name: 'PageNotFound',
        component: PageNotFoundView
    }
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,  
    scrollBehavior (to, from, savedPosition) {
        return new Promise((resolve) => {
            setTimeout(() => {
                if(savedPosition) {
                    resolve(savedPosition)
                } else {
                    resolve({ x: 0, y: 0 })
                }
            }, 100)
        })
    }
})

export default router
