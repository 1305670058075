<template>
    <div class="spinner" v-if="loading"><div class="loader"></div>Loading...</div>  
</template>

<script>
    export default {
        name: 'LoadSpinner',
        props: {
            loading: Boolean,
        },
    }
</script>

<style scoped>
    .spinner {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 150px;
        min-width: 100%;
    }

    .loader {
        border: 16px solid #f3f3f3; /* Light grey */
        border-top: 16px solid #f1c144; /* Blue */
        
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>