<template>
     <a @click="scrollToTop"  class="scrollbutton"  id="scrollbuttonid"> 
        <font-awesome-icon :icon="['fas', 'circle-up']" />
    </a> 
</template>

<script>
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ScrollToTop',
        components: { FontAwesomeIcon },
        methods: {
            scrollToTop() {
                window.scrollTo({top: 0, behavior: 'smooth'});
            }
        },
        mounted() {            
            let mysBtn = document.getElementById('scrollbuttonid')

            window.addEventListener('scroll', function () {
                if (document.body.scrollTop > 300
                    || document.documentElement.scrollTop > 300) {
                    mysBtn.style.display = 'block';
                } else {
                    mysBtn.style.display = 'none';
                }
            });
        }
    }
</script>

<style scoped>
    .scrollbutton {
        display: none;
        position: fixed;
        right: 0;
        right: 30px;
        bottom: 30px;
        background-color: #f1c144;
        color: #e8e8e8;
        padding: 10px;
        border-radius: 5px;
        text-decoration: none;
        font-size: 20px;
        cursor: pointer;
        box-shadow: 0 0 16px black;
    }

    @media (max-width: 768px) {
        .scrollbutton {
            right: 15px;
            bottom: 15px;
        }
    }
</style>