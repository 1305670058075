<template>    
    <TopNav />
    <div class="main-content">
        <Sidebar :type="type" />
        <ProductList :type="type" />
    </div>
</template>

<script>

    import { useHead } from '@unhead/vue'
    import Sidebar from '@/components/Sidebar.vue';
    import TopNav from '@/components/TopNav.vue';
    import ProductList from '@/components/ProductList.vue';

    export default {
        name: 'TypeVew',
        components: { 
            Sidebar, 
            TopNav, 
            ProductList 
        },
        props: {
            type: String
        },
        watch:{
            $route (){
                this.updateMetaTags()
            }
        },
        methods: {
            updateMetaTags() {
                useHead({
                    title: "Premium " + this.capitalize(this.type) + " Themes | Customizable and Responsive Designs - shopwebpages.com",
                    meta: [
                        {
                            name: "description",
                            content: "Discover a collection of premium " + this.capitalize(this.type) + " themes designed for flexibility, responsiveness, and ease of use. Perfect for blogs, business websites, and online stores. Get your customizable " + this.capitalize(this.type) + "  theme today!"
                        },
                    ],
                })
            },
            capitalize(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }
        },
        mounted(){
            this.updateMetaTags();
        }
    }
</script>
