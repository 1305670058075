<template>
    <nav class="top-nav-desktop">
        <div class="top-nav-desktop-logo">
            <router-link :to="{ name: 'Home'}">
                <img class="logo" src="../assets/images/logo.webp" alt="Premium Wordpress and HTML Themes">
            </router-link>
        </div>
        <ul class="top-nav-desktop-links">
            <li>
                <router-link :to="{ name: 'Type', params: { type: 'wordpress' }}">
                    <img src="../assets/images/worrdpress.webp" alt="Wordpress Themes" />
                    Wordpress Themes                    
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'Type', params: { type: 'html' } }">
                    <img src="../assets/images/html.png" alt="HTML Templates" />
                    HTML Templates
                </router-link>
            </li>
        </ul>
        <div class="top-nav-desktop-right"></div>
    </nav>


    <nav class="top-nav-mobile">
        <span class="top-nav-mobile-trigger" @click="openNav">&#9776;</span>
        <router-link :to="{ name: 'Home'}">
            <img class="logo" src="../assets/images/logo.webp">
        </router-link>
    </nav>

    <div id="mobileNav" class="top-nav-mobile-overlay">
        <a href="javascript:void(0)" class="closebtn" @click="closeNav">&times;</a>
        <div class="top-nav-mobile-overlay-content">
            <div class="sidenav">
                <router-link :to="{ name: 'Home'}">Home</router-link>
                <button class="dropdown-btn">Wordpress Themes</button>
                <div class="dropdown-container">
                    <router-link @click="closeNav" v-for="category in wordpressCategories" 
                        :key="category.categoryId"
                        :to="{ name: 'Category', params: { type: 'wordpress', id: category.categoryId}}">{{ category.categoryName }}</router-link>
                </div>
                <button class="dropdown-btn">HTML Templates</button>
                <div class="dropdown-container">
                    <router-link  @click="closeNav" v-for="category in htmlCategories" 
                        :key="category.categoryId" 
                        :to="{ name: 'Category', params: { type: 'html', id: category.categoryId}}">{{ category.categoryName }}</router-link>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'TopNav',
        data() {
            return {
                wordpressCategories: [],
                htmlCategories: []
            }
        },
        methods: {
            openNav() {
                document.getElementById("mobileNav").style.width = "100%"
            },

            closeNav() {
                document.getElementById("mobileNav").style.width = "0%"
            },

            getWordpressCategories() {
                fetch(process.env.VUE_APP_API_ENDPOINT + "/api/Categories?type=wordpress")
                    .then(response => response.json())
                    .then(data => this.wordpressCategories = data) 
                    .catch(error => console.log(error))
            },

            geHtmlCategories() {
                fetch(process.env.VUE_APP_API_ENDPOINT + "/api/Categories?type=html")
                    .then(response => response.json())
                    .then(data => this.htmlCategories = data) 
                    .catch(error => console.log(error))
            }
        },
        mounted() {
            this.getWordpressCategories()
            this.geHtmlCategories()

            var dropdown = document.getElementsByClassName("dropdown-btn");
            var i;

            for (i = 0; i < dropdown.length; i++) {
                dropdown[i].addEventListener("click", function() {
                    this.classList.toggle("active");
                    var dropdownContent = this.nextElementSibling;
                    
                    if (dropdownContent.style.display === "block") {
                        dropdownContent.style.display = "none";
                    } else {
                        dropdownContent.style.display = "block";
                    }
                });
            }
        }
    }
</script>