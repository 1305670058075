<template>
    <div class="sidebar" v-if="categories">
        <ul>
            <li v-for="category in categories" :key="category.categoryId">
                &nbsp;<font-awesome-icon :icon="['fas', 'list']" />&nbsp;
                <router-link :to="{ name: 'Category', params: { type: this.type.toLowerCase(), id: category.categoryId.toLowerCase()}}">{{ category.categoryName }}</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'SidebarNavigation',
        components: { FontAwesomeIcon },
        props: {
            type: String
        },
        watch:{
            $route (){
                this.loadCategories()
            }
        },
        data() {
            return {
                categories: []
            }
        },
        methods: {
            loadCategories() {
                fetch(process.env.VUE_APP_API_ENDPOINT + "/api/Categories?type=" + this.type)
                    .then(response => response.json())
                    .then(data => this.categories = data) 
                    .catch(error => console.log(error))
            }
        },
        mounted () { 
            this.loadCategories()
        }
    }
</script>