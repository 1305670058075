import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faList, faEye, faArrowLeft, faCartShopping, faCaretDown, faCircleUp, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { createHead } from '@unhead/vue'

library.add(faList)
library.add(faEye)
library.add(faArrowLeft)
library.add(faCartShopping)
library.add(faCaretDown)
library.add(faCircleUp)
library.add(faRotateRight)

const app = createApp(App)
const head = createHead()

app.use(head)
app.use(router)

app.use(VueGtag, {
    config: { id: "G-YBQE1MZL2V" }
});

app.mount('#app')



