<template>
    <div class="similar-products">
        <ul class="products">
            <li v-for="item in data" :key="item.id"> 
                <router-link :to="{ name: 'Product', params: {id : item.themeUrl }}">
                    <div class="product">
                        <img :src="`https://shopwebpages.com/img/products/${item.themeUrl}.webp`" alt="article-cover">
                    </div>
                </router-link>
            </li>        
        </ul>             
    </div>
</template>

<script>
    export default {
        name: 'SimilarProducts',
        props: { data: Array }
    }
</script>