<template>
    <div class="product-list" style="width:100%">
        <LoadSpinner :loading="loading" />
        <div class="product-list-description">
            <h1 id="product-description">{{ title }}</h1>
        </div>
        <ul class="products">
            <li v-for="theme in themes" :key="theme.id"> 
                <router-link v-if="category" :to="{ name: 'ProductCategory', params: {type: theme.type.toLowerCase(), category: category.toLowerCase(), id: theme.themeUrl }}">
                    <div class="product">
                        <img :src="`https://shopwebpages.com/img/products/${theme.productImage}`" alt="article-cover">
                        <div class="content">
                            <p>{{ theme.themeName }}</p>
                            <button @click="goToDemo(theme.demoUrl)"><font-awesome-icon :icon="['fas', 'eye']" /> Live Demo</button>
                        </div>
                    </div>
                </router-link>

                <router-link v-if="!category" :to="{ name: 'Product', params: {type: theme.type.toLowerCase(), id: theme.themeUrl }}">
                    <div class="product">
                        <img :src="`https://shopwebpages.com/img/products/${theme.productImage}`" alt="article-cover">
                        <div class="content">
                            <p>{{ theme.themeName }}</p>
                            <button @click="goToDemo(theme.demoUrl)"><font-awesome-icon :icon="['fas', 'eye']" /> Live Demo</button>
                        </div>
                    </div>
                </router-link>
            </li>        
        </ul> 
        <button class="load-more-btn" v-if="hasNextPage && themes.length > 0" @click="loadMore"><font-awesome-icon :icon="['fas', 'rotate-right']" /> Load More</button>
    </div>
    
    <ScropToTop />
   
</template>

<script>
    import { useHead } from '@unhead/vue'
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
    import LoadSpinner from './LoadSpinner.vue';
    import ScropToTop from './ScropToTop.vue';

    export default {
        name: 'ProductList',
        components: { FontAwesomeIcon, LoadSpinner, ScropToTop },
        props: {
            category: String,
            similar: String,
            type: String,
            tag: String
        },
        watch: {
            async $route () {                
                this.resetThemes()
                await this.loadThemes()            
            }
        },
        data() {
            return {
                themes: [],
                page: 1,
                size: 24,
                hasNextPage: false,
                totalItems: 0,
                title: '',
                loading: false
            };
        },
        methods: {
            async loadThemes() {
                this.loading = true

                if(this.tag) {                   
                    let apiUrl = process.env.VUE_APP_API_ENDPOINT + "/api/Themes/tag?page=" + this.page + "&size=" + this.size
                    
                    apiUrl += "&type=" + this.type
                    apiUrl += "&tag=" + this.tag

                    await this.getThemes(apiUrl)
                } else {
                    let apiUrl = process.env.VUE_APP_API_ENDPOINT + "/api/Themes?page=" + this.page + "&size=" + this.size

                    if(this.type) {
                        apiUrl += "&type=" + this.type
                    }                

                    if(this.category) {
                        apiUrl += "&category=" + this.category
                    }

                    await this.getThemes(apiUrl)
                }
                
                this.setTitle();
                this.updateMetaTags()

                this.loading = false
            },

            async getThemes(apiUrl) {
                await fetch(apiUrl)
                    .then(response => response.json())
                    .then(data => {
                        this.themes.push(...data.items)
                        this.page = data.pageIndex
                        this.totalItems = data.totalItems
                        this.hasNextPage = data.hasNextPage
                    }) 
                    .catch(error => console.log(error))
            },

            setTitle() {
                if(this.tag && this.type) {
                    if(this.type === "wordpress") {
                        this.title = this.formatNumber(this.totalItems) + " Wordpress Themes For " +  this.formatCategory(this.tag) + " Websites"
                    } else {
                        this.title = this.formatNumber(this.totalItems) + " HTML and CSS Templates For " +  this.formatCategory(this.tag) + " Websites"
                    }
                    
                } else if(this.category && this.type) {
                    if(this.type === "wordpress") {
                        this.title = this.formatNumber(this.totalItems) + " Wordpress Themes For " +  this.formatCategory(this.category) + " Websites"
                    } else {
                        this.title = this.formatNumber(this.totalItems) + " HTML and CSS Templates For " +  this.formatCategory(this.category) + " Websites"
                    }
                   
                } else if(!this.category && this.type) {
                    if(this.type === "wordpress") {
                        this.title = this.formatNumber(this.totalItems) + " Wordpress Themes"
                    } else {
                        this.title = this.formatNumber(this.totalItems) + " HTML and CSS Templates"
                    }
                } else {
                     this.title = this.formatNumber(this.totalItems) + " Wordpress Themes and HTML/CSS Templates"
                }
            },

            capitalize(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },

            formatCategory(string) {
                if(string == undefined)
                    return "";

                return this.capitalize(string.replaceAll("-", " "));
            },

            formatNumber(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },

            loadMore(){
               this.page++
               this.loadThemes()
            },

            resetThemes() {
                this.page = 1,
                this.themes = []
            },

            goToDemo(url) {
                window.open(url, '_blank').focus();
            },

            updateMetaTags() {
                const title = this.title + " | shopwebpages.com"
                const description = "Explore " + this.formatNumber(this.totalItems) + " premium " + this.type +" themes designed for " + this.formatCategory(this.category || this.tag) + " websites. Enhance your " + this.formatCategory(this.category || this.tag) + " site with professional, responsive, and customizable templates."

                useHead({
                    title: title,
                    meta: [
                        {
                            name: "robots",
                            content: "index, follow"
                        },
                        {
                            name: "description",
                            content: description
                        },
                        {
                            name: "keywords",
                            content: this.formatCategory(this.category) + " WordPress themes," + this.formatCategory(this.category) + " HTML templates"
                        },
                        {
                            name: "og:title",
                            content: title
                        },
                        {
                            name: "og:description",
                            content: description
                        },
                        {
                            name: "og:image",
                            content:  this.themes[0].imagePreview
                        },
                        {
                            name: "og:url",
                            content: window.location.href
                        },
                        {
                            name: "og:type",
                            content: "website"
                        },
                        {
                            name: "twitter:card",
                            content: this.themes[0].imagePreview
                        },
                        {
                            name: "twitter:title",
                            content: title
                        },
                        {
                            name: "twitter:description",
                            content: description
                        },
                        {
                            name: "twitter:image",
                            content: this.themes[0].imagePreview
                        },
                        {
                            name: "canonical",
                            content: window.location.href
                        }
                    ],
                })
            }
        },
        mounted() {            
            this.loadThemes();
        }
    }
</script>