<template>
    <TopNav />
    <div class="main-content">
        <Sidebar :type="type" />
        <ProductList :type="type" :category="id" />
    </div>
</template>

<script>    
    import Sidebar from '@/components/Sidebar.vue';
    import TopNav from '@/components/TopNav.vue';
    import ProductList from '@/components/ProductList.vue';

    export default {
        name: 'CategoryView',
        components: { 
            ProductList, 
            Sidebar,
            TopNav 
        },
        props: {
            id: String,
            type: String
        },
        watch:{
            $route (){
                this.scrollToTop()
            }
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0,0);
            }
        }
    }   
</script>