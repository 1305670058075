<template>
    <TopNav />
    <ProductList />
</template>

<script>
    import { useHead } from '@unhead/vue'
    import ProductList from '@/components/ProductList.vue';
    import TopNav from '@/components/TopNav.vue';

    export default {
        name: 'HomeView',        
        components: { 
            ProductList, 
            TopNav
        },

        mounted() {
            useHead({
                title: "Premium Wordpress and HTML Themes | Customizable and Responsive Designs - shopwebpages.com",
                meta: [
                    {
                        name: "robots",
                        content: "index, follow"
                    },
                    {
                        name: "description",
                        content: "Discover a collection of premium website wordpress and html themes designed for flexibility, responsiveness, and ease of use. Perfect for blogs, business websites, and online stores. Get your customizable website theme today!"
                    },
                    {
                        name: "keywords",
                        content: "WordPress themes, HTML templates, responsive website templates, customizable themes, premium templates, blog themes, business website templates, ecommerce templates"
                    },
                    {
                        name: "og:title",
                        content: "Premium WordPress & HTML Templates | Customizable & Responsive Designs"
                    },
                    {
                        name: "og:description",
                        content: "Discover premium WordPress and HTML templates designed for flexibility, responsiveness, and ease of use. Perfect for any type of website."
                    },
                    {
                        name: "og:image",
                        content: "../assets/images/wordpress_html_themes.webp"
                    },
                    {
                        name: "og:url",
                        content: "shopwebpages.com"
                    },
                    {
                        name: "og:type",
                        content: "website"
                    },
                    {
                        name: "twitter:card",
                        content: "summary_large_image"
                    },
                    {
                        name: "twitter:title",
                        content: "Premium WordPress & HTML Templates | Customizable & Responsive Designs"
                    },
                    {
                        name: "twitter:description",
                        content: "Check out our collection of premium WordPress and HTML templates, designed to be responsive and customizable for any type of website."
                    },
                    {
                        name: "twitter:image",
                        content: "../assets/images/wordpress_html_themes.webp"
                    },
                    {
                        name: "canonical",
                        content: "https://shopwebpages.com"
                    }
                ],
            })
        }

    }
</script>
