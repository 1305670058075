<template>
    <TopNav />
    <div class="main-content" v-if="themeDetail">
        <Sidebar :type="themeDetail.theme.type"  />
        <div class="product-details">
            <LoadSpinner :loading="loading" />   
            <div class="header">
                <button class="back-btn" @click="$router.back(-1)"><font-awesome-icon :icon="['fas', 'fa-arrow-left']" /> Back </button>
                <h1 class="title">{{ themeDetail.theme.themeName  }}</h1>
            </div>  
            <img class="preview" :src="`https://shopwebpages.com/img/products/${themeDetail.theme.themeUrl}.webp`" :alt="themeDetail.theme.themeName" />  
            <div class="actions">
                <a class="preview-btn" target="_blank" :href="themeDetail.theme.demoUrl"><font-awesome-icon :icon="['fas', 'eye']" />&nbsp;DEMO</a>
                <a class="purchase-btn" target="_blank" :href="themeDetail.theme.url"><font-awesome-icon :icon="['fas', 'fa-cart-shopping']" />&nbsp;PURCHASE</a>
            </div>
            <div class="description">
                <div v-html="themeDetail.theme.pageContent"></div>
            </div>
            <ul class="tags">
                <li v-for="tag in themeDetail.tags" :key="tag.id">
                    <router-link :to="{ name: 'Tags', params: { type:themeDetail.theme.type.toLowerCase(), id: tag.tagId.toLowerCase()}}">{{ tag.tagName }}</router-link>
                </li>
            </ul>
            <div class="actions">
                <a class="preview-btn" target="_blank" :href="themeDetail.theme.demoUrl"><font-awesome-icon :icon="['fas', 'eye']" />&nbsp;DEMO</a>
                <a class="purchase-btn" target="_blank" :href="themeDetail.theme.url"><font-awesome-icon :icon="['fas', 'fa-cart-shopping']" />&nbsp;PURCHASE</a>
            </div>
            <div class="similar">
                <h1>Similar Products</h1>
                <SimilarProducts :data="themeDetail.similarThemes" />
            </div>
        </div> 
    </div>
    <ScropToTop />
</template>

<script>
    import { useHead } from '@unhead/vue'
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
    import Sidebar from '@/components/Sidebar.vue';
    import SimilarProducts from '@/components/SimilarProducts.vue';
    import TopNav from '@/components/TopNav.vue';
    import LoadSpinner from '@/components/LoadSpinner.vue';
    import ScropToTop from '@/components/ScropToTop.vue';

    export default {
        name: 'ProductView',
        components: {  TopNav, Sidebar, SimilarProducts, FontAwesomeIcon, LoadSpinner, ScropToTop },
        props: {
            id: String,
            type: String,
            category: String
        },
        data() {
            return {
                themeDetail: null,
                loading: false
            }
        },
        watch:{
            async $route (){
                await this.loadTheme()
                this.scrollToTop()
            }
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0,0);
            },

            async loadTheme() {
                this.loading = true
                await fetch(process.env.VUE_APP_API_ENDPOINT + "/api/Themes/" + this.id)
                    .then(response => response.json())
                    .then(data => this.themeDetail = data)
                    .catch(error => console.log(error))

                this.updateMetaTags();
                this.loading = false
            },
            updateMetaTags() {
                const title = this.themeDetail.theme.pageTitle
                const description =  this.themeDetail.theme.pageDescription

                useHead({
                    title: title,
                    meta: [
                        {
                            name: "robots",
                            content: "index, follow"
                        },
                        {
                            name: "description",
                            content: description
                        },
                        {
                            name: "keywords",
                            content:  this.themeDetail.theme.pageKeywords
                        },
                        {
                            name: "og:title",
                            content: title
                        },
                        {
                            name: "og:description",
                            content: description
                        },
                        {
                            name: "og:image",
                            content:  this.themeDetail.theme.imagePreview
                        },
                        {
                            name: "og:url",
                            content: window.location.href
                        },
                        {
                            name: "og:type",
                            content: "website"
                        },
                        {
                            name: "twitter:card",
                            content: this.themeDetail.theme.imagePreview
                        },
                        {
                            name: "twitter:title",
                            content: title
                        },
                        {
                            name: "twitter:description",
                            content: description
                        },
                        {
                            name: "twitter:image",
                            content: this.themeDetail.theme.imagePreview
                        },
                        {
                            name: "canonical ",
                            content: "https://shopwebpages.com/product/" + this.type + "/" + this.themeDetail.theme.themeUrl
                        }
                    ],
                })
            }
        },
       
        mounted () { 
            this.scrollToTop()
            this.loadTheme() 
        }
    }
</script>