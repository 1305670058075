<template> 
    
    <TopNav />
    <div class="main-content">
        <Sidebar :type="type" />
        <div class="content">
            <ProductList :type="type" :tag="id" />
        </div>
    </div>
</template>

<script>
    import Sidebar from '@/components/Sidebar.vue';
    import TopNav from '@/components/TopNav.vue';
    import ProductList from '@/components/ProductList.vue';

    export default {
        name: 'TagsView',
        components: { 
            ProductList, 
            Sidebar, 
            TopNav 
        },
        props: {
            type: String,
            id: String
        }
    }   
</script>